(function () {
    'use strict';

    /*global angular:false,$:false*/
    angular.module('amphora.blog').controller('BlogPostCtrl', ['$scope', '$http', '$routeParams', '$location',
        function ($scope, $http, $routeParams, $location) {
            var slug = $routeParams.slug || '';
            $scope.post = null;
            $scope.loading = true;

            $http.get('api/get_post?date_format=Y-m-d&slug=' + slug, { cache: true }).then(function (response) {
                console.log('response', response);
                $scope.post = response.data.post;
                if(!$scope.post) {
                    $location.path('/404');
                }
                $scope.loading = false;
            }, function () {
                $scope.loading = false;
                $location.path('/404');
            });
        }]);
}());