(function () {
    'use strict';

    /*global angular:false*/
    angular.module('amphora.services', ['amphora.utility']).constant('servicesDefault', {
        bannerCopy: 'Whether you&#39;re looking for a partner to create or refresh a brand, or if you&#39;re looking for full-service marketing capabilities, talk to us. We&#39;d love to help with connecting your brand to consumers.',
        brandStrategy: ['Brand Architecture', 'Positioning', 'Marketing Plans', 'Product Launches', 'Competitive Research', 'Lead Gen', 'Sales Competitions', 'Viral Marketing'],
        brandLanguage: ['Corporate Identity', 'Name Development', 'Taglines', 'Copywriting', 'Collateral', 'Web Copy', 'SEO', 'Google Ad Words', 'E-mail Campaigns', 'Social Media'],
        brandImagery: ['Logo Development', 'Package Design', 'Line Extensions', 'Concept Boards', 'Sales Samples', 'Retail POS', 'Shelf Set Studies', 'Tradeshow Design', 'Grand Format', '3-D Visualization']
    });
}());