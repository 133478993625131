(function () {
    'use strict';

    /*global angular:false*/
    angular.module('amphora.utility.parseParagraphs', ['amphora.utility.splitArray']).filter('parseParagraphs', ['$filter', function ($filter) {
        return function (rawText) {
            var paragraphs = [], columns = [];

            angular.forEach(rawText.split('\r\n\r\n'), function (item) {
                paragraphs.push(item);
            });

            columns = $filter('splitArray')(paragraphs, 2);

            return {
                column1: columns[0],
                column2: columns[1]
            };
        };
    }]);
}());