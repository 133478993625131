(function () {
    'use strict';

    /*global angular:false,$:false*/
    angular.module('amphora.work').controller('WorkCtrl', ['$scope', 'WorkItems', function ($scope, WorkItems) {
        $scope.workItems = [];
        $scope.loading = true;

        WorkItems.getWorkItems().then(function (workItems) {
            $scope.workItems = workItems;
            $scope.loading = false;
        }, function () {
            $scope.loading = false;
        });
    }]);
}());