(function () {
    'use strict';

    /*global angular:false,$:false*/
    angular.module('amphora.work').controller('WorkShowcaseModalCtrl', ['$scope', '$modalInstance', '$filter', 'workItems', 'selectedItem',
        function ($scope, $modalInstance, $filter, workItems, selectedItem) {
            selectedItem.active = true;
            $scope.workItems = $filter('reorder')(workItems, selectedItem);

            function closeModal() {
                $modalInstance.close(true);
            }

            $scope.ok = closeModal;
            $scope.cancel = closeModal;
        }]);
}());