(function () {
    'use strict';

    /*global angular:false*/
    angular.module('amphora.utility.page', []).factory('Page', ['$location', function ($location) {
        var title = '',
            offset = 50;

        /** @function isActive 
         *
         * @description
         * Determine if the given page is currently active
         *
         * @param {String} route - the route to check
         *
         * @return {Boolean}
         */
        function isActive(route) {
            if (!$location.path() && route === '/') {
                return true;
            }
            if (route === '/work' && $location.path().indexOf('/work') === 0) {
                return true;
            }
            return route === $location.path();
        }

        /** @function setTitle 
         *
         * @description
         * Set the page title
         *
         * @param {String} newTitle
         */
        function setTitle(newTitle) {
            title = newTitle;
        }

        /** @function getTitle 
         *
         * @description
         * Get the title
         *
         * @retun {String} title
         */
        function getTitle() {
            return title;
        }

        function getToday() {
            return new Date();
        }

        function setOffset(value) {
            offset = value;
        }

        function getOffset() {
            return offset;
        }

        return {
            title: getTitle,
            setTitle: setTitle,
            isActive: isActive,
            today: getToday,
            setOffset: setOffset,
            getOffset: getOffset
        };
    }]);
}());