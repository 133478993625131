(function () {
    'use strict';

    /*global angular:false,$:false*/
    angular.module('amphora.utility.backgroundImage', []).directive('bgImage', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var url = attrs.bgImage;

                if (url) {
                    element.css({
                        'background-image': 'url(' + url + ')'
                    });
                }
            }
        };
    });
}());