(function () {
    'use strict';

    /*global angular:false*/
    angular.module('amphora.carousel').controller('AmphoraCarouselCtrl', ['$scope', '$http', 'WorkItems', 'Page', function ($scope, $http, WorkItems, Page) {
        $scope.Page = Page;
        // Pause until we load everything
        $scope.interval = -1;
        $scope.loading = true;

        $scope.splash = {};

        $http.get('api/get_page?slug=home', { cache: true }).then(function (response) {
            var page = response.data.page || {};

            $scope.splash.stepOne = page.custom_fields.step_1_copy[0];
            $scope.splash.stepTwo = page.custom_fields.step_2_copy[0];
            $scope.splash.stepThree = page.custom_fields.step_3_copy[0];
            $scope.splash.stepFour = page.custom_fields.step_4_copy[0];
            $scope.splash.image = page.attachments[0] ? page.attachments[0].url : '';

            $scope.slides = [
                {
                    type: 'splash',
                    stepOne: page.custom_fields.step_1_copy[0],
                    stepTwo: page.custom_fields.step_2_copy[0],
                    stepThree: page.custom_fields.step_3_copy[0],
                    stepFour: page.custom_fields.step_4_copy[0],
                    image: page.attachments[0] ? page.attachments[0].url : ''
                }];

            WorkItems.getFeaturedItems().then(function (featuredItems) {
                angular.forEach(featuredItems, function (item) {
                    $scope.slides.push({
                        title: item.title,
                        description: item.shortDescription,
                        img: item.showcaseImage,
                        type: 'featured-work'
                    });
                });
                $scope.loading = false;
            });
        }, function () {
            $scope.loading = false;
        });

        var firstSlideActive = false;
        $scope.$watch(function () {
            if ($scope.slides) {
                if ($scope.slides[0].active) {
                    if (!firstSlideActive) {
                        firstSlideActive = true;
                        $scope.interval = 12000;
                    }
                } else {
                    firstSlideActive = false;
                    $scope.interval = 3500;
                }
            }
        });
    }]);
}());