(function () {
    'use strict';

    /*global angular:false*/
    angular.module('amphora').config(['$routeProvider', '$locationProvider', function ($routeProvider, $locationProvider) {
        $routeProvider.when('/', {
            templateUrl: 'app/home/home.htm',
            controller: 'HomeCtrl'
        }).when('/home', {
            templateUrl: 'app/home/home.htm',
            controller: 'HomeCtrl'
        }).when('/work', {
            templateUrl: 'app/work/work.htm',
            controller: 'WorkCtrl',
            title: 'Work'
        }).when('/work/:slug', {
            templateUrl: 'app/work/work-detail.htm',
            controller: 'WorkDetailCtrl',
            title: 'Work'
        }).when('/about', {
            templateUrl: 'app/about/about.htm',
            controller: 'AboutCtrl',
            title: 'About'
        }).when('/services', {
            templateUrl: 'app/services/services.htm',
            controller: 'ServicesCtrl',
            title: 'Services'
        }).when('/contact', {
            templateUrl: 'app/contact/contact.htm',
            controller: 'ContactCtrl',
            title: 'Contact'
        }).when('/blog', {
            templateUrl: 'app/blog/blog.htm',
            controller: 'BlogCtrl',
            title: 'Blog'
        }).when('/blog/:slug', {
            templateUrl: 'app/blog/blog-post.htm',
            controller: 'BlogPostCtrl',
            title: 'Blog'
        }).when('/404', {
            templateUrl: 'app/error/404.htm',
            controller: 'HomeCtrl',
            title: 'Page Not Found'
        }).otherwise({
            redirectTo: '/404'
        });

        // use the HTML5 History API
        $locationProvider.html5Mode(true);
    }]).run(['$rootScope', '$route', 'Page', function ($rootScope, $route, Page) {
        $rootScope.$on("$routeChangeSuccess", function () {
            // Change page title, based on Route information
            Page.setTitle($route.current.title);
        });
    }]);
}());