(function () {
    'use strict';

    /*global angular:false*/
    angular.module('amphora.work').factory('WorkItems', ['$http', '$q', '$filter', function ($http, $q, $filter) {
        var workItems = [];

        /** @function getImage 
         *
         * @description
         * Get the image from the item's attachments
         *
         * @param {WorkItem} item
         * @param {Integer} imageId
         *
         * @return {String} imageUrl
         */
        function getImage(item, imageId) {
            var image = $filter('filter')(item.attachments, { id: imageId });
            return image[0] ? image[0].url : '';
        }

        function loadWorkItems() {
            var defer = $q.defer();

            $http.get('api/get_posts/?post_type=amphora_work_item&count=999', { cache: true }).then(function (response) {
                var items = $filter('orderBy')(response.data.posts, 'id') || [];

                workItems = items.map(function (item) {
                    return {
                        id: item.id,
                        title: item.title,
                        description: item.custom_fields.description[0],
                        featured: item.custom_fields.featured[0],
                        fullName: item.custom_fields.full_name[0],
                        slug: item.slug,
                        shortDescription: item.custom_fields.short_description[0],
                        showcaseImage: getImage(item, item.custom_fields.showcase_image[0]),
                        teaserImage: getImage(item, item.custom_fields.teaser_image[0])
                    };
                });
                defer.resolve(workItems);
            }, function (error) {
                defer.reject(error);
            });

            return defer.promise;
        }

        function getWorkItems() {
            var defer = $q.defer();

            if (workItems.length) {
                defer.resolve(workItems);
            } else {
                loadWorkItems().then(function (items) {
                    defer.resolve(items);
                }, function (error) {
                    defer.reject(error);
                });
            }

            return defer.promise;
        }

        function getFeaturedItems() {
            var defer = $q.defer(),
                featuredItems = [];

            if (workItems.length) {
                featuredItems = $filter('filter')(workItems, { featured: true });
                defer.resolve(featuredItems);
            } else {
                loadWorkItems().then(function (items) {
                    featuredItems = $filter('filter')(items, { featured: 1 });
                    defer.resolve(featuredItems);
                }, function (error) {
                    defer.reject(error);
                });
            }

            return defer.promise;
        }

        // Load work items on run
        loadWorkItems();

        return {
            getWorkItems: getWorkItems,
            getFeaturedItems: getFeaturedItems
        };
    }]);
}());