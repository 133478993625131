(function () {
    'use strict';

    /*global angular:false*/
    angular.module('amphora.about').controller('AboutCtrl', ['$scope', '$http', '$filter', 'aboutDefault', function ($scope, $http, $filter, aboutDefault) {
        $scope.about = {};
        $scope.loading = true;

        $http.get('api/get_page?slug=about', { cache: true }).then(function (response) {
            var page = response.data.page.custom_fields || {};

            $scope.about.headline = page.headline[0] || aboutDefault.headline;
            $scope.about.bannerCopy = $filter('parseHtml')(page.banner_copy[0] || aboutDefault.bannerCopy);
            $scope.about.mainCopy = $filter('parseParagraphs')(page.main_copy[0] || aboutDefault.mainCopy);
            $scope.loading = false;
        }, function () {
            // Apply defaults on error
            $scope.about = aboutDefault;
            $scope.loading = false;
        });
    }]);
}());