(function () {
    'use strict';

    /*global angular:false*/
    angular.module('amphora.utility.spanWrap', []).directive('spanWrap', [function () {
        return {
            restrict: 'A',
            scope: {
                text: '@spanWrap'
            },
            link: function (scope, element, attrs) {
                var spans = '<span>' + scope.text.replace(/\s/g, '</span>&nbsp;<span>') + '</span>';
                element[0].innerHTML = spans;
            }
        };
    }]);
}());