(function () {
    'use strict';

    /*global angular:false,$:false*/
    angular.module('amphora.utility.affix', ['duScroll']).directive('affix', ['$window', function ($window) {
        return {
            restrict: 'A',
            link: function (scope, element) {
                $($window).on('scroll', function () {
                    scope.offset = $('#amphora-carousel').height() || 0;
                    if ($($window).scrollTop() > scope.offset) {
                        element.addClass('navbar-fixed-top');
                    } else {
                        element.removeClass('navbar-fixed-top');
                    }
                });
            }
        };
    }]);
}());