(function () {
    'use strict';

    /*global angular:false*/
    angular.module('amphora.utility.reorder', []).filter('reorder', function () {
        return function (array, firstItem) {
            var newArray = [],
                selectedIndex = array.indexOf(firstItem),
                itemsAfter = angular.copy(array).splice(selectedIndex + 1),
                itemsBefore = angular.copy(array).splice(0, selectedIndex);

            newArray.push(firstItem);
            newArray = newArray.concat(itemsAfter).concat(itemsBefore);

            return newArray;
        };
    });
}());