(function () {
    'use strict';

    /*global angular:false*/
    angular.module('amphora.contact').controller('ContactCtrl', ['$scope', '$http', '$filter', 'contactDefault', function ($scope, $http, $filter, contactDefault) {
        $scope.contact = {};
        $scope.loading = true;

        $http.get('api/get_page?slug=contact', { cache: true }).then(function (response) {
            var page = response.data.page.custom_fields || {};
            
            $scope.contact.contactCopy = $filter('parseHtml')(page.contact_copy[0]) || contactDefault.contactCopy;
            $scope.contact.contactEmail = page.contact_email[0] || contactDefault.contactEmail;
            $scope.contact.facebookUrl = page.facebook_url[0] || contactDefault.facebookUrl;
            $scope.contact.linkedInUrl = page.linkedin_url[0] || contactDefault.linkedInUrl;
            $scope.contact.careersUrl = page.careers_url[0] || contactDefault.careersUrl;
            $scope.contact.privacyUrl = page.privacy_url[0] || contactDefault.privacyUrl;
            $scope.contact.termsAndConditionsUrl = page.terms_and_conditions_url[0] || contactDefault.termsAndConditionsUrl;
            $scope.loading = false;
        }, function () {
            // Apply defaults on error
            $scope.contact = contactDefault;
            $scope.loading = false;
        });
    }]);
}());