(function () {
    'use strict';

    /*global angular:false*/
    angular.module('amphora.services').controller('ServicesCtrl', ['$scope', '$http', '$filter', 'servicesDefault', function ($scope, $http, $filter, servicesDefault) {
        $scope.services = {};
        $scope.loading = true;

        $http.get('api/get_page?slug=services', { cache: true }).then(function (response) {
            var page = response.data.page.custom_fields || {};

            $scope.services.bannerCopy = $filter('parseHtml')(page.banner_copy[0] || servicesDefault.bannerCopy);
            $scope.services.brandStrategy = page.brand_strategy[0].split(',') || servicesDefault.brandStrategy;
            $scope.services.brandLanguage = page.brand_language[0].split(',') || servicesDefault.brandLanguage;
            $scope.services.brandImagery = page.brand_imagery[0].split(',') || servicesDefault.brandImagery;
            $scope.loading = false;
        }, function () {
            // Apply defaults on error
            $scope.services = servicesDefault;
            $scope.loading = false;
        });
    }]);
}());