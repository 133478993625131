(function () {
    'use strict';

    /*global angular:false*/
    angular.module('amphora.about', ['ngSanitize', 'amphora.utility']).constant('aboutDefault', {
        headline: 'Uplifting Brands',
        bannerCopy: '<p>It starts with a question, that leads to another.</p><p>Which lead to ideas.</p><p>Ideas that inspire, transform and deliver.</p><p>Ideas that help us create visual realities<br />that strengthen brands and build business.</p>',
        mainCopy: {
            column1: ['This is our practice and our passion. Creating and uplifting brands based on solid, structured thought.', 'As a full-service branding and design agency, we specialize in creating, planning and managing brand strategies. Like the two handles of an amphora, we use strategy and design to lift brands to new heights — creating a meaningful and valuable brand expression for customers. Delivered to your timeline — and your budget.'],
            column2: ['How? We&#39;re smart, efficient and value-minded. And, as an independent division of OEC Graphics, an award- winning pre-press company, we have access to additional resources to support your goals.', 'Whether you&#39;re looking for a partner to create or refresh a package, or if you&#39;re looking for full-service marketing capabilities, talk to us. We&#39;d love to help with connecting your brand to consumers.']
        }
    });
}());