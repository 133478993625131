(function () {
    'use strict';

    /*global angular:false*/
    angular.module('amphora.utility.splitArray', []).filter('splitArray', function () {
        return function (array, columns) {
            var length = array.length, output = [], i = 0, size;

            while (i < length) {
                size = Math.ceil((length - i) / columns);
                columns = columns - 1;
                output.push(array.slice(i, i + size));
                i = i + size;
            }

            return output;
        };
    });
}());