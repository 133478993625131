(function () {
    'use strict';

    /*global angular:false*/
    angular.module('amphora', ['ngRoute', 'amphora.nav', 'amphora.home', 'amphora.work', 'amphora.about', 'amphora.services', 'amphora.contact', 'amphora.blog', 'ui.bootstrap']).run(['$route', '$rootScope', '$location', function ($route, $rootScope, $location) {
        var original = $location.path;
        $location.path = function (path, reload) {
            if (reload === false) {
                var lastRoute = $route.current,
                    un = $rootScope.$on('$locationChangeSuccess', function () {
                        $route.current = lastRoute;
                        un();
                    });
            }
            return original.apply($location, [path]);
        };
    }]);
}());