(function () {
    'use strict';

    /*global angular:false*/
    angular.module('amphora.blog').controller('BlogCtrl', ['$scope', '$http', function ($scope, $http) {
        $scope.posts = [];
        $scope.loading = true;

        $http.get('api/get_recent_posts?date_format=Y-m-d', { cache: true }).then(function (response) {
            $scope.posts = response.data.posts || [];
            $scope.loading = false;
        }, function () {
            $scope.loading = false;
        });
    }]);
}());