(function () {
    'use strict';

    /*global angular:false,$:false*/
    angular.module('amphora.work').controller('WorkDetailCtrl', ['$scope', '$filter', '$routeParams', '$location', 'WorkItems',
        function ($scope, $filter, $routeParams, $location, WorkItems) {
            var slug = $routeParams.slug || '',
                selectedItem = null;
            $scope.workItems = [];
            $scope.loading = true;

            WorkItems.getWorkItems().then(function (workItems) {
                workItems = angular.copy(workItems);
                selectedItem = $filter('filter')(workItems, {
                    slug: slug
                })[0];
                if (!selectedItem) {
                    $location.path('/404');
                }
                $scope.workItems = $filter('reorder')(workItems, selectedItem);
                $scope.loading = false;
            });

            $scope.$watch(function () {
                var i = 0;
                for (i; i < $scope.workItems.length; i++) {
                    if ($scope.workItems[i].active) {
                        return $scope.workItems[i];
                    }
                }
            }, function (currentSlide, previousSlide) {
                if (currentSlide !== previousSlide) {
                    $location.path('/work/' + currentSlide.slug, false);
                }
            });
        }]);
}());