(function () {
    'use strict';

    /*global angular:false*/
    angular.module('amphora.utility.parseHtml', []).filter('parseHtml', function () {
        return function (rawText) {
            var html = '<p>' + rawText + '</p>';
            html = html.replace(/\r\n\r\n/g, '</p><p>').replace(/\n\n/g, '</p><p>');
            html = html.replace(/\r\n/g, '<br />').replace(/\n/g, '<br />');
            return html;
        };
    });
}());