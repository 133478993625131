(function () {
    'use strict';

    /*global angular:false*/
    angular.module('amphora.contact', ['amphora.utility']).constant('contactDefault', {
        contactCopy: '<p>909 South Perkins Street<br />Appleton, WI 54914<br />920 560 4512</p><p>+</p><p>208 North Broadway<br />Santa Ana, CA 92701<br />949 632 6905</p>',
        contactEmail: 'ljepson@weareamphora.com',
        facebookUrl: '#/facebook',
        linkedInUrl: '#/linked-in',
        careersUrl: 'http://www.oecgraphics.com/index.php/careers',
        privacyUrl: '#/privacy',
        termsAndConditionsUrl: '#/terms-and-conditions'
    });
}());